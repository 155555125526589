/* src/css/Footer.css */
footer {
    background-color: var(--primary-color);
    text-align: center;
    padding: 20px 0;
    width: 100%;
    position: relative;
    bottom: 0;
  }
  
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Makes the body take at least 100% of the viewport height */
    margin: 0;
  }
  
  main {
    flex: 1; /* Ensures the main content expands to fill available space */
  }