@font-face {
  font-family: 'LondonFont';
  src: url('../fonts/london_between/LondonBetween.ttf');
}

:root {
  --primary-color: #1a1625;
  --secondary-color: #282828;
  --accent-color: #5e43f3;
  --background-color: #121212;
  --text-color: #ffffff;
  --secondary-Text-color: #ffffff;
}

body {
  font-family: 'LondonFont', sans-serif;
  line-height: 1.6;
  background-color: var(--background-color);
  color: var(--text-color);
  flex: 1;
}

/* Reset some default styles */
body, h1, p, ul, li, a {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}

