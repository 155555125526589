/* src/css/App.css */
.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../images/spectrum-flashes-coloured-light.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  main {
    flex: 1;
    padding: 20px;
  }