.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .form-container {
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border: 2px solid var(--accent-color);
    padding: 40px;
    border-radius: 8px;
    min-width: 35%;
    text-align: center;
  }
  
  .form-container h2 {
    margin-bottom: 20px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    align-self: flex-start;
    padding-left: 20%;
  }

  .form-group {
    display: flex;
    justify-content: center; /* Horizontally center the input */
    align-items: center;     /* Vertically center (optional, depending on the layout) */
    flex-direction: column;  /* Stack label and input vertically */
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .form-group input {
    max-width: 80%; /* Adjust width as needed */
    min-width: 60%;
    padding: 10px;
    border-radius: 15px;
    border: 2px solid var(--accent-color);
    background-color: rgba(0, 0, 80, 0.4);
    color: var(--text-color);
  }

  /* Handle browser autofill for webkit-based browsers (Chrome, Safari) */
.form-group input:-webkit-autofill {
    background-color: rgba(0, 0, 80, 0.4) !important; /* Same background color */
    color: var(--text-color) !important; /* Same text color */
    border: 2px solid var(--accent-color); /* Ensures border is visible */
    -webkit-text-fill-color: var(--text-color) !important; /* Ensures the text remains your chosen color */
    transition: background-color 5000s ease-in-out 0s; /* Prevents background from changing */
  }
  
  /* Handle browser autofill for Firefox */
  .form-group input:-moz-autofill {
    background-color: rgba(0, 0, 80, 0.4) !important; /* Same background color */
    color: var(--text-color) !important; /* Same text color */
    border: 2px solid var(--accent-color); /* Ensures border is visible */
  }
  
  /* Optional: To reset styles after autofill is done (when the user interacts with the field) */
  .form-group input:-webkit-autofill:focus {
    background-color: rgba(0, 0, 80, 0.4) !important;
    color: var(--text-color) !important;
  }
  
  .btn1{
    display: inline-block;
    background-color: var(--accent-color);
    color: white;
    padding: 10px 40px;
    font-size: 16px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }

  .btn2{
    display: inline-block;
    background-color: var(--background-color);
    color: white;
    padding: 5px 10px;
    font-size: 16px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  
  .btn1:hover {
    background-color: var(--secondary-color);
  }

  .btn2:hover {
    background-color: var(--secondary-color);
  }

  .space{
    margin: 10px;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;        /* Full viewport width */
    height: 100vh;       /* Full viewport height */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;  /* Center the spinner horizontally */
    align-items: center;      /* Center the spinner vertically */
    z-index: 9999;       /* High z-index to overlay all content */
    pointer-events: auto; /* Ensures clicks are blocked */
  }

  /* HTML: <div class="loader"></div> */
.loader {
  width: 65px;
  aspect-ratio: 1;
  position:fixed;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  border-radius: 50px;
  box-shadow: 0 0 0 3px inset #fff;
  animation: l5 2.5s infinite;
}
.loader:after {
  animation-delay: -1.25s;
  border-radius: 0;
}
@keyframes l5{
  0%    {inset:0    35px 35px 0   }
  12.5% {inset:0    35px 0    0   }
  25%   {inset:35px 35px 0    0   }
  37.5% {inset:35px 0    0    0   }
  50%   {inset:35px 0    0    35px}
  62.5% {inset:0    0    0    35px}
  75%   {inset:0    0    35px 35px}
  87.5% {inset:0    0    35px 0   }
  100%  {inset:0    35px 35px 0   }
}