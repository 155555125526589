header {
    background-color: var(--primary-color);
    padding: 10px 20px;
}

header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .logo {
    color: var(--secondary-Text-color);
    font-size: 24px;
    font-weight: bold;
}

header .nav-links {
    list-style: none;
    display: flex;
    margin-left: 20px;
}

.buttonTag {
    display: inline-block;
    background-color: var(--primary-color);
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .buttonTag:hover {
    background-color: var(--accent-color);
  }

/* Mobile Styles (up to 768px) */
@media (max-width: 768px) {
    .buttonTag {
        padding: 6px 8px;
        font-size: 10px;
    }

    header .logo {
        font-size: 16px;
    }
}

/* Tablet Styles (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .buttonTag {
        padding: 10px 20px;
        font-size: 18px;
    }

    header .logo {
        font-size: 22px;
    }
}