/* Hero section styles */
.hero {
    text-align: center;
    margin: auto;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 5px solid var(--accent-color);
    border-radius: 20px;
}

.hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.hero p {
    font-size: 18px;
    margin-bottom: 30px;
}
